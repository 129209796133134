.tag-select {
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.tag-select .tag-na {
  margin-bottom: 5px;
}
.tag-select .tag-selector-container {
  margin-bottom: 15px;
}
